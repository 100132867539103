import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";

import "bootstrap"; // js
import "./main.scss";

window.onload = function() {
	AOS.init();

	const brandName = document.querySelector(".brand-name");
	const navItems = document.querySelectorAll(".nav-item");
	const aboutItem = document.querySelector(".about-item");
	const homeItem = document.querySelector(".home-item");
	const headerBtn = document.querySelector(".header-btn");

	function handleLinkClick(e) {
		const activeElem = document.querySelector(".nav-item.active");

		activeElem.classList.remove("active");
		e.target.parentElement.classList.add("active");
	}

	brandName.addEventListener("click", (e) => {
		const activeElem = document.querySelector(".nav-item.active");

		activeElem.classList.remove("active");
		homeItem.classList.add("active");
	});

	navItems.forEach((item) => {
		item.addEventListener("click", handleLinkClick);
	});

	headerBtn.addEventListener("click", (e) => {
		const activeElem = document.querySelector(".nav-item.active");

		activeElem.classList.remove("active");
		aboutItem.classList.add("active");
	});

	// close nav if clicked on items and document
	$(document).click(function(event) {
		const click = $(event.target);
		const _open = $(".navbar-collapse").hasClass("show");
		if (_open === true && !click.hasClass("navbar-toggler")) {
			$(".navbar-toggler").click();
		}
	});
};
